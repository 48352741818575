<template>
  <div>
    <v-menu
      offset-y
      max-height="250"
      v-if="
        role !== 'developer' && role !== 'marketing' && role !== 'marketingv2'
      "
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          id="no-background-hover"
          class="text-capitalize lead-phase-badge"
          :loading="loading"
          :disabled="loading"
          rounded
          elevation="0"
          :color="
            getLeadColor(
              phaseSelected.data.phase,
              phaseSelected.data.tracking_phase
            )
          "
          v-bind="attrs"
          v-on="on"
        >
          <div>
            {{ phaseSelected.text }}
          </div>
          <v-icon class="px-1" small>
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>
      <v-list elevation="0">
        <v-list-item
          v-for="(item, index) in phases"
          :key="index"
          @click="sendUpdateLeadPhase(item)"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div v-else>
      <v-chip
        id="no-background-hover"
        v-if="lead && lead.postponed !== undefined"
        class="lead-phase-badge"
        :color="
          getLeadColor(
            phaseSelected.data.phase,
            phaseSelected.data.tracking_phase
          )
        "
      >
        {{ phaseSelected.text }}
      </v-chip>
      <v-chip v-else>
        Asignado-
      </v-chip>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
export default {
  props: {
    lead: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      role: localStorage.getItem("user_role"),
      loading: false
    };
  },
  computed: {
    ...mapState({
      phases: state => state.leads.filters?.phases,
      loadingSt: state => state.leads.loading
    }),
    phaseSelected: {
      get() {
        const phases = this.phases ?? [];
        const _phaseSelected = phases.find(phase => {
          return phase
            ? phase.data.phase === this.lead.phase &&
                phase.data.tracking_phase === this.lead.tracking_phase &&
                phase.data.operation_phase === this.lead.operation_phase
            : undefined;
        });
        return (
          _phaseSelected ?? {
            text: "Por Asignar",
            value: "unassigned",
            data: {
              phase: "active",
              tracking_phase: "unassigned",
              operation_phase: ""
            }
          }
        );
      },
      set(value) {
        this.phaseSelected = value;
      }
    }
  },
  watch: {},
  methods: {
    ...mapActions({
      fetchUpdatelLead: "leads/fetchUpdatelLead"
    }),
    ...mapMutations({
      setLoading: "leads/setLoading"
    }),
    async sendUpdateLeadPhase(value) {
      let updateData = {
        leadId: this.lead._id,
        contactLeadId: this.lead.contact_lead._id,
        lead: {
          phase: value.data.phase,
          tracking_phase: value.data.tracking_phase,
          operation_phase: value.data.operation_phase
        }
      };
      await this.sendUpdateLead(updateData, value);
    },
    async sendUpdateLead(updateData, value) {
      let self = this;
      self
        .fetchUpdatelLead(updateData)
        .then(() => {
          this.phaseSelected = value;
        })
        .catch(() => {
          self.$snotify.error(
            "Error!!!",
            `Ocurrio un problema actualizar el lead.`,
            {
              closeOnClick: true,
              timeout: 4000
            }
          );
        })
        .finally(() => {
          setTimeout(() => {
            self.innerLoading = false;
            self.loading = false;
            self.setLoading(false);
          }, 1000);
        });
    },
    getLeadColor(phase, trackingPhase) {
      if (!phase) {
        return "grey lighten-3";
      } else if (phase === "active" && trackingPhase === "unassigned") {
        return "red lighten-5";
      } else if (phase === "finished") {
        return "amber lighten-5";
      } else {
        return "grey lighten-3";
      }
    }
  }
};
</script>

<style scoped>
tbody tr:nth-of-type(odd) {
  background-color: rgba(204, 204, 204, 0.081);
}
body tr:hover td {
  background-color: rgba(142, 188, 241, 0.322);
}

body tr:nth-of-type(odd):hover td {
  background-color: rgba(142, 188, 241, 0.322);
}

.font-size-table-header {
  font-size: 13px !important;
}
.font-size-table-row {
  font-size: 14px !important;
}

.uri-text {
  display: inline-block;
  margin-left: 5px;
}

.link {
  color: blue !important;
  background-color: none;
}

.lead-created-date {
  font-size: 11px;
}
.lead-list .table-responsive {
  overflow-x: scroll;
}

#leads-table .broker-image {
  height: 52px;
  margin: 6px 0;
  width: auto;
  border-radius: 24%;
}
.broker-name {
  font-size: 10px;
  display: block;
}
.last-update {
  position: absolute;
  bottom: -16px;
  right: -10px;
  font-size: 9px;
}
.lead-created-at,
.lead-interested {
  font-size: 11px;
  margin-left: -11px;
}

.lead-observations {
  width: 100px;
  font-size: 11px;
  margin-left: 3px;
}
#no-background-hover::before {
  background-color: transparent !important;
}
.lead-profile-badge {
  padding: 6px;
  color: #fff;
}
.lead-phase-badge.unassigned {
  border-color: #ff4500;
}
.lead-phase-badge.discarded,
.v-chip.v-chip--outlined.v-chip.v-chip.discarded {
  background-color: #eee !important;
}
.lead-profile-badge.profile-a {
  background-color: #008000;
}
.lead-profile-badge.profile-b {
  background-color: #ffa500;
}
.lead-profile-badge.profile-c {
  background-color: #2e6cf1;
}
.lead-profile-badge.profile-d {
  background-color: #a0a0a0;
}
.VueTables__search,
.VueTables__limit {
  display: inline-block;
  padding: 12px 0;
  margin-bottom: 0;
}
.VueTables__limit {
  float: right;
}
.profile-filter {
  margin-right: 6px;
}
.lead-position {
  position: absolute;
  margin-left: -115px;
  margin-top: 40px;
}
.budget-size {
  font-size: 13px;
  line-height: 1.5;
}
/* CSS tooltip*/
.tooltip {
  display: inline-block;
  position: relative;
  border-bottom: 1px dotted #666;
  text-align: left;
}

.container-loading {
  height: calc(100vh - 180px) !important;
}

.loading-circle {
  height: 150px !important;
  width: 150px !important;
  left: calc(50vw - 120px) !important;
  margin-top: 180px !important;
}
.lead-budget {
  font-size: 14px;
  color: #008000;
}
.row-repeat-lead {
  color: #ff5552;
}

#leadsTable .v-data-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: white;
}

#leadsTable .v-data-table__wrapper {
  margin-bottom: 60px;
}
</style>
